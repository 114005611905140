<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <img src="../../../../public/images/IPC/network/download.jpg" width="100%">
    <div class="pet">
      <div class="content3_box">
        <div class="content3_box_1" @click="light1">
          <img src="../../../../public/images/IPC/network/1.jpg" width="100%" height="100%">
          <div class="content3_box_sise">智能摄像灯1</div>
        </div>
        <div class="content3_box_1" @click="light2">
          <img src="../../../../public/images/IPC/network/2.jpg" width="100%" height="100%">
          <div class="content3_box_sise">智能摄像灯2</div>
        </div>
      </div>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import foot from '../../../../src/components/foot'
import elMenu from "../../../components/elMenu";
import navigation from "../../../components/navigation";
export default {
  name: "network",
  components: {
    elMenu, foot,navigation
  },
  methods:{
    light1(){
      this.$router.push('/light1')
    },
    light2(){
      this.$router.push('/light2')
    },

  }
}
</script>

<style scoped>
.pet {
  height: 750px;
}

.content3_box {
  padding-top: 150px;
  display: flex;
  justify-content: space-around;
}

.content3_box_1 {
  width: 20%;
  height: 400px;
  border: 1px solid #ececec;
}

.content3_box_sise {
  line-height: 100px;
  text-align: center;
  font-size: 18px;
}
</style>